import React, { Component } from 'react';
import styled from 'styled-components';
import { Facebook } from './images/Facebook';
import { Twitter } from './images/Twitter';
import { Instagram } from './images/Instagram';

const FooterWrapper = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:100%;
    padding:40px 0px;
    background:#272827;
`;

const FooterText = styled.div`
    font-size:20px;
    color:#fff;
    font-family:'Roboto Condensed', sans-serif;
    font-weight:bold;
    text-transform:uppercase;
    text-align:center;
    padding:10px;
`;

const FooterLinks = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    width:95px;
`;

const FooterLink = styled.a`
    display:flex;
    justify-content:center;
    align-items:center;
    width:25px;
    height:25px;
    padding:10px;
`;

const FacebookLink = styled.a`
    display:flex;
    justify-content:center;
    align-items:center;
    width:18px;
    height:25px;
    padding:10px 5px 10px 10px;
`;

export class Footer extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <FooterWrapper>
                <FooterText>
                    The Lane Head, 4-5 Main Road, Ryton, Gateshead, NE40 3NA<br />
                    Email: info@thelanehead.com
                </FooterText>
                <FooterLinks>
                    <FacebookLink href='https://www.facebook.com/thelanehead'>
                        <Facebook dimensions="25px"/>
                    </FacebookLink>
                    <FooterLink href='https://www.twitter.com/thelanehead'>
                        <Twitter dimensions='25px' />
                    </FooterLink>
                    <FooterLink href='https://www.instagram.com/thelanehead'>
                        <Instagram dimensions='25px' />
                    </FooterLink>
                </FooterLinks>
            </FooterWrapper>
        );
    }
}