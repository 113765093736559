import React, { Component } from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import { Contact, Home, Summary } from './components';

class App extends Component {
    render() {
        return (
            <Router>
                <Switch>
                    <Route path="/contact" render={() => <Home content={<Contact />} />} />
                    <Route path="/" render={() => <Home content={<Summary />} />} />
                </Switch>
            </Router>
        );
    }
}

export default App;
