import * as React from 'react';

export const Twitter = ({ dimensions, color } ) => (
    <svg width={dimensions || '22px'} height={dimensions || '22px'} viewBox="0 0 22 22" version="1.1">
        <defs>
            <path d="M22.4256003,2.19314569 C23.1806406,1.66055938 24.192239,2.34918819 23.9736093,3.24692703 C23.6310685,4.65347058 22.9444409,5.94991003 21.9788599,7.02132259 C21.9937365,7.18329745 22.0014671,7.3463315 22.0020066,7.51030895 C22.0020066,19.3697472 10.5559398,25.4620085 0.516363648,19.8844662 C-0.404318339,19.3729762 -0.0106389431,17.9692391 1.04175123,18.0110991 C2.72835076,18.0781854 4.39795342,17.7428694 5.91616311,17.0435499 C3.71202262,15.6849506 2.30067136,13.8862895 1.60829924,11.7585118 C0.893424718,9.56158036 0.997770257,7.25288415 1.59415826,5.06028119 C1.77456266,4.39702972 1.95792376,3.89728084 2.08819503,3.60417048 C2.40386638,2.89390995 3.37366873,2.79952999 3.8203631,3.43559805 C5.491951,5.81584675 8.13498283,7.30170538 11.001725,7.51681475 C10.9863639,5.23227435 12.3906808,3.17608052 14.5274996,2.36050079 C16.4598913,1.62294672 18.6215935,2.04110729 20.134814,3.39534626 C20.9469085,3.09683321 21.7171835,2.69284499 22.4256003,2.19314569 Z M20.1503395,5.49783918 C19.7737087,5.61125078 19.3655742,5.49333979 19.1074524,5.19654675 C18.1491781,4.09470557 16.6049363,3.70831412 15.2406764,4.22902397 C13.8764166,4.74973383 12.9823419,6.06678046 13.0020066,7.54030895 L13.0020066,8.54030895 C13.0020066,9.08249669 12.5699333,9.52591881 12.0279277,9.53997294 C8.76719289,9.62452337 5.65195562,8.34094251 3.40606656,6.05356852 C3.01103234,7.75866021 2.98214764,9.51702981 3.51014541,11.1396571 C4.18717213,13.2202758 5.73701866,14.9093301 8.40814505,16.0964974 C9.12490821,16.4150588 9.21261237,17.3971738 8.5636365,17.8376975 C7.44453411,18.5973423 6.21832478,19.1674612 4.93400793,19.5340545 C12.6131607,21.5543029 20.0020073,16.4565164 20.002012,7.51361498 C20.0012958,7.29696781 19.9804657,7.08085507 19.9397898,6.86805943 C19.8771193,6.54019857 19.9821683,6.2026853 20.2198339,5.96830227 C20.4425963,5.74861654 20.6489193,5.51446738 20.8376958,5.26780813 C20.6111912,5.3513637 20.381972,5.42808966 20.1503395,5.49783918 Z" id="path-twitter"></path>
        </defs>
        <g id="twitter" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Feather" transform="translate(-613.000000, -85.000000)">
                <g id="twitter" transform="translate(613.000000, 83.000000)">
                    <mask id="mask-2" fill="white">
                        <use xlinkHref="#path-twitter"></use>
                    </mask>
                    <use id="Shape" fill={color ? color : '#fff'} fillRule="nonzero" xlinkHref="#path-twitter"></use>
                </g>
            </g>
        </g>
    </svg>
);