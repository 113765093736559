import React, { Component } from 'react';
import styled from 'styled-components';
import headerImage from './images/header.svg';

const HeaderWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    height:100px;
    width: 100%;
    background:#757575;
    transition: 0.3s;
    padding-top:10px;
    padding-bottom:10px;
`;

const HeaderLogo = styled.img`
    display:flex;
    padding:10px;
    width:400px;
    height:100px;
    transition: 0.3s;
`;

export class Header extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <HeaderWrapper>
                <HeaderLogo
                    src={headerImage}
                />
            </HeaderWrapper>
        );
    }
}