import React, { Component } from 'react';
import styled from 'styled-components';
import { Banner, Footer, Header, Nav } from './index';

const AppContainer = styled.div`
    width:100%;
    overflow:auto;
`;

export class Home extends Component {
    render() {
        return (
            <AppContainer className="App" id="App">
                <Header />
                <Nav />
                <Banner />
                {this.props.content}
                <Footer />
            </AppContainer>

        );
    }
}