import React, { Component } from 'react';
import styled from 'styled-components';
import banner from './images/banner.jpg';

const BannerWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width:100%;
    height:400px;
    max-height:
    background:white;
    background-image:url(${banner});
    background-position: center;
    background-size: cover;
    padding-bottom:20px;
    border-bottom:3px solid #453e36;
`;

const BannerText = styled.div`
    display:block;
    width:80%;
    color:#fff;
    font-size:40px;
    font-family:'Staatliches', cursive;
    text-shadow: 1px 1px 1px #000;
    text-align:center;
`;

export class Banner extends Component {
    render() {
        return (
            <BannerWrapper>
                <BannerText>Your alternative living room. With the finest real ale, craft lager and gin.</BannerText>
            </BannerWrapper>
        );
    }
}