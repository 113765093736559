import * as React from 'react';

export const Facebook = ({ dimensions, color } ) => (
    <svg width={dimensions || '18px'} height={dimensions || '25px'} viewBox="0 0 18 25" version="1.1">
        <defs>
            <path d="M9,7 C9,3.6862915 11.6862915,1 15,1 L18,1 C18.5522847,1 19,1.44771525 19,2 L19,6 C19,6.55228475 18.5522847,7 18,7 L15,7 L15,9 L18,9 C18.6505712,9 19.1279292,9.6113889 18.9701425,10.2425356 L17.9701425,14.2425356 C17.8588507,14.6877026 17.4588676,15 17,15 L15,15 L15,22 C15,22.5522847 14.5522847,23 14,23 L10,23 C9.44771525,23 9,22.5522847 9,22 L9,15 L7,15 C6.44771525,15 6,14.5522847 6,14 L6,10 C6,9.44771525 6.44771525,9 7,9 L9,9 L9,7 Z M17,3 L15,3 C12.790861,3 11,4.790861 11,7 L11,10 C11,10.5522847 10.5522847,11 10,11 L8,11 L8,13 L10,13 C10.5522847,13 11,13.4477153 11,14 L11,21 L13,21 L13,14 C13,13.4477153 13.4477153,13 14,13 L16.2192236,13 L16.7192236,11 L14,11 C13.4477153,11 13,10.5522847 13,10 L13,7 C13,5.8954305 13.8954305,5 15,5 L17,5 L17,3 Z" id="path-facebook"></path>
        </defs>
        <g id="Facebook" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Feather" transform="translate(-318.000000, -460.000000)">
                <g id="facebook" transform="translate(312.000000, 459.000000)">
                    <mask id="mask-2" fill="white">
                        <use xlinkHref="#path-facebook"></use>
                    </mask>
                    <use id="Shape" fill={color ? color : '#fff'} fillRule="nonzero" xlinkHref="#path-facebook"></use>
                </g>
            </g>
        </g>
    </svg>
);