import React, { Component } from 'react';
import styled from 'styled-components';
import bg from './images/bg.jpg';
import { Facebook } from './images/Facebook';
import { Twitter } from './images/Twitter';
import { Instagram } from './images/Instagram';
import { HomeIcon } from './images/HomeIcon';

const ContactContainer = styled.div`
    display:flex;
    justify-content: center;
    background-image:url(${bg});
    width:100%;
    padding:30px 0px;
    border-bottom:3px solid #453e36;
`;

const ContactWrapper = styled.div`
    display:flex;
    width:900px;
    flex-wrap:wrap;
`;

const ContactInfo = styled.div`
    display:block;
    flex-direction:column;
    flex-wrap:wrap;
    flex:1;
    padding:20px;
    min-width:310px;
`;

const ContactInfoHeader = styled.div`
    display:flex;
    width:100%;
    height:70px;
    font-size:40px;
    font-family:'Staatliches', cursive;
    color:#fff;
`;

const OpeningTimesHeader = styled.div`
    display:flex;
    width:100%;
    height:30px;
    font-size:20px;
    font-family:'Staatliches', cursive;
    color:#fff;
`;

const OpeningTimesText = styled.div`
    display:flex;
    width:100%;
    font-size:16px;
    font-family:'Roboto Condensed', sans-serif;
    color:#fff;
    padding-bottom:20px;
`;

const TelephoneInfoHeader = styled.div`
    display:flex;
    width:100%;
    height:30px;
    font-size:20px;
    font-family:'Staatliches', cursive;
    color:#fff;
`;

const TelephoneInfoText = styled.div`
    display:flex;
    width:100%;
    font-size:16px;
    font-family:'Roboto Condensed', sans-serif;
    color:#fff;
`;

const IconWrapper = styled.div`
    display:flex;
    flex-direction:row;
    padding-bottom:20px;
`;

const IconText = styled.div`
    color:#fff;
    font-size:16px;
    font-family:'Roboto Condensed', sans-serif;
    padding:0px 10px;
    text-align:left;
`;

const IconTextHeader = styled.span`
    font-weight:bold;
`;

const ContactMap = styled.div`
    display:flex;
    flex:1;
    min-width:310px;
    height:500px;
    padding:0px 20px;
`;

export class Contact extends Component {
    render() {
        return (
            <ContactContainer>
                <ContactWrapper>
                    <ContactInfo>
                        <ContactInfoHeader>Contact Us</ContactInfoHeader>
                        <IconWrapper>
                            <HomeIcon dimensions="40px" color="#fff"/>
                            <IconText>
                                <IconTextHeader>TheLaneHead</IconTextHeader><br />
                                4-5 Main Road,<br />
                                Ryton,<br />
                                Gateshead,<br />
                                NE40 3NA
                            </IconText>
                        </IconWrapper>
                        <IconWrapper>
                            <Facebook dimensions="40px" color="#fff"/>
                            <IconText>
                                <IconTextHeader>Facebook:</IconTextHeader><br />
                                @TheLaneHead
                            </IconText>
                        </IconWrapper>
                        <IconWrapper>
                            <Twitter dimensions="40px" color="#fff"/>
                            <IconText>
                                <IconTextHeader>Twitter:</IconTextHeader><br />
                                @TheLaneHead
                            </IconText>
                        </IconWrapper>
                        <IconWrapper>
                            <Instagram dimensions="40px" color="#fff"/>
                            <IconText>
                                <IconTextHeader>Instagram:</IconTextHeader><br />
                                thelanehead
                            </IconText>
                        </IconWrapper>
                        <OpeningTimesHeader>Opening Times</OpeningTimesHeader>
                        <OpeningTimesText>
                            Monday-Thursday: 13:00-21:30<br />
                            Friday-Saturday: 13:00-22:00<br />
                            Sunday: 13:00-21:30
                        </OpeningTimesText>
                        <TelephoneInfoHeader>Telephone</TelephoneInfoHeader>
                        <TelephoneInfoText>
                            (+44) 07552-775018 
                        </TelephoneInfoText>

                    </ContactInfo>
                    <ContactMap>
                    <iframe width='100%' height='100%' id='mapcanvas' src='https://maps.google.com/maps?q=54.97265,-1.7650057&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=&amp;output=embed' frameBorder='0' scrolling='no' marginHeight='0' marginWidth='0'></iframe>
                    </ContactMap>
                </ContactWrapper>
            </ContactContainer>
        );
    }
}