import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const NavWrapper = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    flex:1;
    flex-direction:row;
    height:30px;
    overflow:hidden;
    background:#453e36;
`;

const NavItem = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    width:100px;
`;

const NavItemLink = styled(Link)`
    display:block;
    height:100%;
    width:100%;
    text-decoration:none;
    color:#fff;
    font-size:18px;
    font-family:'Staatliches', cursive;
    text-align:center;

    &:hover {
        background:#fff;
        color:#453e36;
    }
`;

export class Nav extends Component {
  render() {
    return (
        <NavWrapper>
            <NavItem>
                <NavItemLink to='/'>Home</NavItemLink>
            </NavItem>
            <NavItem>
                <NavItemLink to='/contact'>Contact</NavItemLink>
            </NavItem>
        </NavWrapper>
    );
  }
}