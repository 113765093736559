import React, { Component } from 'react';
import styled from 'styled-components';
import bg from './images/bg.jpg';
import wheel from './images/wheel.svg';

const SummaryContainer = styled.div`
    display:flex;
    justify-content: center;
    background-image:url(${bg});
    width:100%;
    border-bottom:3px solid #453e36;
`;

const SummaryWrapper = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    flex-wrap: wrap;
    width:80%;
    padding:20px;
`;

const SummaryText = styled.div`
    order:1;
    width:500px;
    min-width:300px;
    align-items:center;
    color:#fff;
    font-size:18px;
    font-family:'Roboto Condensed', sans-serif;
    text-shadow: 1px 1px 1px #000;
    padding-bottom:20px;

    @media (min-width: 1000px) {
        padding-right: 100px;
    }
`;

const SummaryImage = styled.img `
    order:2;
    height:300px;
`;

export class Summary extends Component {
    render() {
        const { content } = this.props;

        return (
            <SummaryContainer>
                <SummaryWrapper>
                    <SummaryText>Welcome to The Lane Head, Ryton’s first micro pub.<br /><br />
                    Expect a warm welcome and a cold drink, with plenty of options to choose from. Open daily and independently run, it’s the perfect place to get together with friends and enjoy a glass or two of your favourite tipple - your canine companions are welcome too!
                    </SummaryText>
                    <SummaryImage src={wheel} />
                </SummaryWrapper>
            </SummaryContainer>
        );
    }
}