import * as React from 'react';

export const HomeIcon = ({ dimensions, color } ) => (
    <svg width={dimensions || '22px'} height={dimensions || '22px'} viewBox="0 0 22 22" version="1.1">
        <defs>
            <path d="M16,21 L19,21 C19.5522847,21 20,20.5522847 20,20 L20,9.48908381 L12,3.26686158 L4,9.48908381 L4,20 C4,20.5522847 4.44771525,21 5,21 L8,21 L8,12 C8,11.4477153 8.44771525,11 9,11 L15,11 C15.5522847,11 16,11.4477153 16,12 L16,21 Z M14,21 L14,13 L10,13 L10,21 L14,21 Z M2.38605939,8.21064778 L11.3860594,1.21064778 C11.7471699,0.929784072 12.2528301,0.929784072 12.6139406,1.21064778 L21.6139406,8.21064778 C21.8575272,8.40010398 22,8.69140957 22,9 L22,20 C22,21.6568542 20.6568542,23 19,23 L5,23 C3.34314575,23 2,21.6568542 2,20 L2,9 C2,8.69140957 2.14247284,8.40010398 2.38605939,8.21064778 Z" id="path-home" />
            <rect id="path-3" x="0" y="0" width="24" height="24" />
            <mask id="mask-4" maskContentUnits="userSpaceOnUse" maskUnits="objectBoundingBox" x="0" y="0" width="24" height="24" fill="white">
                <use xlinkHref="#path-home"/>
            </mask>
        </defs>
        <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="home" transform="translate(-20.000000, -6.000000)">
                <g id="home" transform="translate(18.000000, 5.000000)">
                    <mask id="mask-2" fill="white">
                        <use xlinkHref="#path-home" />
                    </mask>
                    <use id="Combined-Shape" fill={color || '#FFF'} fillRule="nonzero" xlinkHref="#path-home" />
                </g>
            </g>
        </g>
    </svg>
);