import * as React from 'react';

export const Instagram = ({ dimensions, color } ) => (
    <svg width={dimensions || '22px'} height={dimensions || '22px'} viewBox="0 0 22 22" version="1.1">
    <defs>
        <path d="M7,1 L17,1 C20.3137085,1 23,3.6862915 23,7 L23,17 C23,20.3137085 20.3137085,23 17,23 L7,23 C3.6862915,23 1,20.3137085 1,17 L1,7 C1,3.6862915 3.6862915,1 7,1 Z M7,3 C4.790861,3 3,4.790861 3,7 L3,17 C3,19.209139 4.790861,21 7,21 L17,21 C19.209139,21 21,19.209139 21,17 L21,7 C21,4.790861 19.209139,3 17,3 L7,3 Z M16.9891835,11.2233165 C17.3055865,13.357031 16.2179658,15.4531222 14.2912166,16.4229088 C12.3644675,17.3926955 10.0329942,17.0175298 8.50773217,15.4922678 C6.98247016,13.9670058 6.60730452,11.6355325 7.57709117,9.70878336 C8.54687783,7.78203422 10.642969,6.69441347 12.7766835,7.01081652 C14.9554044,7.33389346 16.6661065,9.04459556 16.9891835,11.2233165 Z M15.0108165,11.5166835 C14.8169704,10.2094509 13.7905491,9.18302964 12.4833165,8.98918348 C11.2030878,8.79934165 9.94543313,9.4519141 9.36356114,10.6079636 C8.78168914,11.7640131 9.00678853,13.1628971 9.92194574,14.0780543 C10.8371029,14.9932115 12.2359869,15.2183109 13.3920364,14.6364389 C14.5480859,14.0545669 15.2006583,12.7969122 15.0108165,11.5166835 Z" id="path-instagram"></path>
    </defs>
        <g id="instagram" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Feather" transform="translate(-769.000000, -321.000000)">
                <g id="instagram" transform="translate(768.000000, 320.000000)">
                    <mask id="mask-2" fill="white">
                        <use xlinkHref="#path-instagram"></use>
                    </mask>
                    <use id="Shape" fill={color ? color : '#fff'} fillRule="nonzero" xlinkHref="#path-instagram"></use>
                </g>
            </g>
        </g>
    </svg>
);